import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MenuApiService } from 'src/lib/repository/menu/menu.api';
import { IMenu } from 'src/lib/repository/menu/menu.model';

/**
 * FIXME: 재구현
 */
@Component({
  selector: 'app-template-content',
  templateUrl: './template-content.component.html',
  styleUrls: ['./template-content.component.scss'],
})
export class TemplateContentComponent implements OnInit {
  submenuList: IMenu[] = [];

  baseUrlPath?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private menuApi: MenuApiService,
  ) {}

  // 메뉴리스트
  readonly menuList = this.menuApi.menuList;

  ngOnInit(): void {
    this.submenuList = this.getMenuList();

    this.route.url.subscribe((baseUrl) => {
      this.baseUrlPath = `/${baseUrl?.toString()?.split('/')[1]}`;
      console.log('baseUrlPath = ' + this.baseUrlPath);
    });

    //・에라 발생시 처리 추가 예정

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.submenuList = this.getMenuList();
        console.log(this.router.url);
      });

    //  this.router.events
    // .subscribe(
    //   (event) =>  {
    //     event instanceof NavigationEnd ;
    // //    console.log(event) ;
    // },  (error) => { console.log(error)
    // },  () => {
    //   this.submenuList = this.getMenuList();
    //   console.log(this.router.url);
    // });
  }

  /**
   *
   */
  getBaseMenu(): IMenu | undefined {
    const basePath = `/${this.router.url.split('/')[1]}`;
    return this.menuList.find((menu) => {
      return menu.url === basePath;
    });
  }

  /**
   *
   * @returns
   */
  getMenuList(): any[] {
    return this.getBaseMenu()?.childMenuList ?? [];
  }

  /**
   *
   * @returns
   */
  getContentTitle(): string {
    console.log(this.getMenuList());
    return this.getMenuList().find((menu) => {
      return this.router.url.includes(menu.path);
    })?.name;
  }

  // 제목
  getLayoutTitle(): string {
    return this.getBaseMenu()?.title ?? '';
  }
}
