<section class="content-section">
  <div class="wrapper">
    <aside>
      <h2>
        {{ getLayoutTitle() | translate }}
      </h2>
      <menu>
        <li
          *ngFor="let submenu of submenuList"
          [routerLink]="submenu.url"
          routerLinkActive="on"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a>{{ submenu.title! | translate }}</a>
        </li>
      </menu>
    </aside>
    <div class="content small">
      <h2>{{ getContentTitle() | translate }}</h2>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
